<h2 mat-dialog-title>Send emails to customers</h2>
<mat-dialog-content class="mat-typography">
  <mat-form-field class="w-full" appearance="outline">
    <mat-label>Subject</mat-label>
    <input matInput placeholder="Ex. 100 Main St" [formControl]="subjectControl">
  </mat-form-field>
  <mat-form-field class="w-full" appearance="outline">
    <mat-label>Body</mat-label>
    <textarea matInput rows="10" [formControl]="bodyControl"></textarea>
  </mat-form-field>

  
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
  <button mat-raised-button color="primary" (click)="send()" cdkFocusInitial>Send</button>
</mat-dialog-actions>