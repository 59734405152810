import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { RepositoryService } from "src/app/core/services/repository.service";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import {
  OrderItem,
  PurchaseData,
  StockItem,
} from 'src/app/shared/models/purchase.models';
import { DropdownItemsRenderer } from "../cell-renderers/po-dropdown.component";
import { RemoveBtnRenderer } from "../cell-renderers/po-remove-btn-renderer.component";
import { POItemsBySupplierService } from "./po-items.service";
import { POService } from "../po-view/po.service";
import * as moment from "moment";
import { getHeaderTemplate } from "src/app/shared/utils/ag-grid-helper";
import { Subject } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";
import { InventoryService } from "src/app/shared/services/inventory.service";
import { HelperService } from "src/app/shared/services/helper.service";
import { GeneralSettingsService } from "src/app/core/services/general-settings.service";
import { GeneralSettings } from "src/app/core/models/general-settings.model";
import { SupplierProductScreen } from '../../../shared/models/product-screen.models';
import { POL } from '../../../shared/models/extended-properties.models';

//#region Models
class Purchase {
  locationID: string;
  supplierID: string;

  containerSize: string;
  reference: string;
  reference2: string;
  supplierInvoiceNumber;
  orderDate: Date;
  goodsReadyDate: string; //Date;
  shippingDate: string; //Date;
  dockingDate: string; //Date;
  expectedDeliverySlot: string; //Date;
  port: string;
  orderNumber: string;
  status: number;
  conversionRate: number;
  items: PurchaseItem[];
}

class PurchaseItem {
  stockItemID: string;
  quantity: number;
  cost: number;
  taxRate: number;
  packSize: number;
  weight: number;
  ctnqty: number;
}

//#endregion

@Component({
  selector: "app-po-creation",
  templateUrl: "./po-creation.component.html",
  styleUrls: ["./po-creation.component.css"],
  providers: [DatePipe],
})
export class POCreationComponent implements OnInit, OnDestroy {

  
  loadingData = false;
  hideDownloadButton = true;
  poNumber;
  dateOrder;
  referenceOfPO;
  goodsReadyDate;
  shippingDate: Date;
  dockingDate: Date;
  expectedDeliverySlot: Date;
  referenceOfPO2;
  suppInvNo;
  conversionRate: number = 1.3;
  POLs: POL[];
  selectedPOL: POL;
  suppliers: SupplierProductScreen[];
  selectedSupplier: SupplierProductScreen;
  stockItems: StockItem[];
  selectedItem: StockItem;
  containerSizes: string[] = ["N/A", "20 FT", "40 FT", "40 FT HQ", "PART"];
  selectedContainerSize: string;
  statuses: string[] = ["PENDING", "OPEN", "DELIVERED"];
  selectedStatus: string;
  lastPOId: string;
  hasNotLastPOId = function (): boolean {
    return this.lastPOId == null;
  };
  isContainerSizeBigger = false;
  changePONumber: string;
  currentPO: PurchaseData;
  public settings: GeneralSettings;
  public disableBoxQtyAlign: boolean = false;

  //#region ValueGetters


  valueTotalCBMFormatter = function (params) {
    if (
      params.data.stockItemID == "total" ||
      params.data.stockItemID == "dropdown"
    ) {
      return "";
    } else {
      return parseFloat(params.data.totalCBM).toFixed(4);
    }
  };

  valueCBMFormatter = function (params) {
    if (
      params.data.stockItemID == "total" ||
      params.data.stockItemID == "dropdown"
    ) {
      return "";
    } else {
      return parseFloat(params.data.cbm).toFixed(4);
    }
  };

  // grid: remove totals for required fields
  totalValueGetterUnitCost = function (params) {
    if (
      params.data.stockItemID == "total" ||
      params.data.stockItemID == "dropdown"
    ) {
      return "";
    } else {
      return parseFloat(params.data.unitCost).toFixed(2);
    }
  };

  totalValueGetterCTNQTY = function (params) {
    if (
      params.data.stockItemID == "total" ||
      params.data.stockItemID == "dropdown"
    ) {
      return "";
    } else {
      return params.data.cartonQty;
    }
  };

  dropdownValueWeight = function (params) {
    if (params.data.stockItemID == "dropdown") {
      return "";
    } else {
      // var num_parts = parseFloat(params.data.weight).toFixed(2).split(".");
      // num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      // return num_parts.join(".");
      return params.data.weight * params.data.quantity;
    }
  };

  dropdownValueLineTotal = function (params) {
    if (params.data.stockItemID == "dropdown") {
      return "";
    } else {
      return params.data.lineTotal.toFixed(2);
    }
  };

  dropdownValueGetterQty = function (params) {
    if (params.data.stockItemID == "dropdown") {
      return "";
    } else {
      return params.data.quantity;
    }
  };
  //#endregion

  thousands_separators(num) {
    var num_parts = num.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts.join(".");
  }

  // SKU Sort - from lowest to highest
  SKUComparator(val1, val2) {
    var po1 = val1.substring(0, 2);
    var isCA1 = /[a-zA-Z]/.test(po1); // IF PO starts with 'CA%'

    var po2 = val2.substring(0, 2);
    var isCA2 = /[a-zA-Z]/.test(po2); // IF PO starts with 'CA%'

    let a = 0;
    let b = 0;
    var num1 = "";
    var num2 = "";

    if (isCA1) {
      num1 = val1.substr(2);
    } else {
      num1 = val1;
    }

    a = parseInt(num1);

    if (isNaN(a)) {
      a = -1;
    }

    if (isCA2) {
      num2 = val2.substr(2);
    } else {
      num2 = val2;
    }

    b = parseInt(num2);

    if (isNaN(b)) {
      b = -1;
    }

    if (a == b) {
      return 0;
    }
    return a > b ? 1 : -1;
  }

  // grid: Columns
  columnDefs = [
    {
      headerName: "SKU",
      field: "sku",
      colId: "items",
      width: 100,
      editable: false,
      cellRenderer: "dropdownItemsRenderer",
      sort: "asc",
      comparator: this.SKUComparator,
    },
    { headerName: "Title", field: "description", width: 130 },
    {
      headerName: "QTY",
      field: "quantity",
      width: 40,
      editable: true,
      sortable: true,
      valueGetter: this.dropdownValueGetterQty,
    },
    {
      headerName: "Unit Cost",
      field: "unitCost",
      width: 60,
      editable: true,
      sortable: true,
      valueGetter: this.totalValueGetterUnitCost,
    },
    {
      headerName: "Carton QTY",
      field: "cartonQty",
      width: 40,
      sortable: true,
      valueGetter: this.totalValueGetterCTNQTY,
    },
    {
      headerName: "CBM",
      field: "cbm",
      width: 40,
      sortable: true,
      valueFormatter: this.valueCBMFormatter,
    },
    {
      headerName: "Total CBM",
      field: "totalCBM",
      width: 55,
      sortable: true,
      valueFormatter: this.valueTotalCBMFormatter,
    },
    {
      headerName: "Weight",
      field: "weight",
      width: 60,
      sortable: true,
      valueGetter: this.dropdownValueWeight,
      valueFormatter: digitFormatter,
    },
    {
      headerName: "Line Total",
      field: "lineTotal",
      width: 60,
      sortable: true,
      valueGetter: this.dropdownValueLineTotal,
    },
    {
      headerName: "Remove item",
      field: "value",
      colId: "params",
      width: 35,
      editable: false,
      cellRenderer: "removeBtnRenderer",
    },
  ];

  // total values
  totalQty;
  totalCBM;
  totalWeight;
  totalWeightFloat;
  totalLine;

  // grid: orderItems
  rowData: OrderItem[] = [];

  // grid: headers height
  public headerHeight;

  // grid: def header styles
  public defaultColDef;

  // grid: for updating and editing grid order items data
  public gridApi;

  // grid: extension components - remove button and dropdown
  frameworkComponents: any;

  // selected items for PO
  orderItems: OrderItem[] = [];
  destroyedComponentSubj = new Subject<void>();

  constructor(
    private appService: POItemsBySupplierService,
    private inventoryService: InventoryService,
    private snackBar: SnackBarService,
    private repo: RepositoryService,
    public datepipe: DatePipe,
    private poService: POService,
    private router: Router,
    private helper: HelperService,
    private settingsService: GeneralSettingsService,
    private cdr: ChangeDetectorRef,
  ) {
    this.headerHeight = 50;
    this.changePONumber = "";

    this.defaultColDef = {
      resizable: true,
      wrapText: true,
      autoHeight: true,
      sortable: true,
      filter: true,
      tooltipComponent: "GridTooltipComponent",
      headerComponentParams: {
        template: getHeaderTemplate(),
      },
    };

    // mat-input set default dates
    this.dateOrder = new Date();
    this.goodsReadyDate = new Date();

    // mat-input select and set new po number
    this.getNewPONumber();

    // mat-select: set default status
    this.selectedStatus = this.statuses[1];
    this.selectedContainerSize = this.containerSizes[0];

    this.frameworkComponents = {
      removeBtnRenderer: RemoveBtnRenderer,
      dropdownItemsRenderer: DropdownItemsRenderer,
    };
  }

  // Select suppliers and locations on load
  ngOnInit(): void {
    this.getSuppliers();
    this.selectedContainerSize = this.containerSizes[0];
    this.selectedStatus = this.statuses[1];
    this.getPOLs();

    this.settingsService.get().pipe(
      tap((settings: GeneralSettings) => {
        this.settings = settings;
        this.conversionRate = this.settings?.defaultExchangeRate ?? 1.3;
      })
    ).subscribe();
    
  }

  checkPurchaseFromProcurement(): void {
    if (this.appService.procurementSupplierId) {
      this.appService.procurementPurchaseItems.forEach((x: any) => {
        const stockItem = this.stockItems.find(i => i.stockItemID === x.stockItemID && x.date === this.appService.purchaseDate);
        if (stockItem) {
          var item = new OrderItem();
          item.stockItemID = stockItem.stockItemID;
          item.sku = stockItem.sku;
          item.description = stockItem.description == "0" ? "" : stockItem.description;
          item.cartonQty = stockItem.ctnqty > 0 ? stockItem.ctnqty : 1;
          item.cbm = parseFloat(stockItem.cbm.toFixed(4));
          item.unitCost = stockItem.purchasePrice;
          item.quantity = x.quantity;
          item.factoryItemNo = stockItem.factoryItemNo;
          item.weight = stockItem.weight;
          item.taxRate = stockItem.taxRate;
          item.hide = false;
          item.totalCBM = (item.quantity / item.cartonQty) * item.cbm;
          item.lineTotal = this.helper.valueIsNaN(
            parseInt(item.quantity.toString(), 0) *
            parseFloat(item.unitCost.toString())
          );
          item.totalCBM = this.helper.valueIsNaN(item.totalCBM);
          item.lineTotal = this.helper.valueIsNaN(item.lineTotal);
          item.weight = this.helper.valueIsNaN(item.weight);
          this.poService.poViewChangesSubject.next(true);
          this.rowData.push(item);
            // update grid with items
            this.gridApi.applyTransaction({ add: [item] });
            this.gridApi.redrawRows();
          this.getTotalRow();
          this.appService.procurementPurchaseItems = this.appService.procurementPurchaseItems.filter((i: any) => i.stockItemID !== x.stockItemID);
        }
        
      });

      // this.goodsReadyDate = this.appService.purchaseDate;
      // this.shippingDate = this.addDays(this.goodsReadyDate, 14);
      // this.dockingDate = this.addDays(this.shippingDate, 50);
      // this.expectedDeliverySlot = this.addDays(this.dockingDate, 7);

      this.expectedDeliverySlot = this.appService.purchaseDate;
      this.dockingDate = this.addDays(this.expectedDeliverySlot, -4);
      this.shippingDate = this.addDays(this.dockingDate, -50);
      this.goodsReadyDate = this.addDays(this.shippingDate, -14);
      // this.appService.procurementPurchaseItems = [];
    }
  }

  ngOnDestroy() {
    this.destroyedComponentSubj.next();
    this.destroyedComponentSubj.complete();
  }

  //#region GET data for cmbxs

  getSuppliers() {
    this.inventoryService
      .getSuppliersForProductScreen()
      .pipe(
          tap((result) => {
            this.suppliers = result.filter((x) => x.active);
            this.selectedSupplier = this.suppliers.find(i => i.pkSupplierID === this.appService.procurementSupplierId) ?? this.suppliers[0];
            this.selectedSupplierChange(this.selectedSupplier);
          }),
          takeUntil(this.destroyedComponentSubj)
      )
      .subscribe();
  }

  getPOLs() {
    this.repo
      .getList("api/purchaseProps/POLs")
      .pipe(takeUntil(this.destroyedComponentSubj))
      .subscribe(
        (result) => {
          this.POLs = result;
          var na = new POL({});
          na.name = "N/A";
          na.transitTime = 0;
          this.POLs.unshift(na);
          this.selectedPOL = this.POLs[0];
          this.loadingData = false;
        },
        (error) => {
          this.loadingData = false;
          this.snackBar.error("Cannot get POLs");
        }
      );
  }

  //#endregion

  // matInput - generate new Purchase Order Number
  getNewPONumber(): void {
    this.poNumber = 5000;

    this.repo.get("api/po/getNewPONumber").subscribe(
      (result) => {
        this.poNumber = result;

        this.loadingData = false;
      },
      (error) => {
        this.loadingData = false;
        this.snackBar.error("Something went wrong");
      }
    );
  }

  // mat-select: Cascade cmbx
  selectedSupplierChange(value): void {
    this.loadingData = true;

    this.repo
      .getList(
        "api/po/getStockItemsBySupplier/" + this.selectedSupplier.pkSupplierID
      )
      .subscribe(
        (result) => {
          this.stockItems = result;
          this.selectedItem = this.stockItems[0];
          this.loadingData = false;
          this.appService.stockItems = result;
          this.checkPurchaseFromProcurement();
          this.cdr.detectChanges();
          if (value?.portId) {
            this.selectedPOL = this.POLs.find(i => i.id === value.portId);
          }
        },
        (error) => {
          this.loadingData = false;

          this.snackBar.error("Something went wrong");
        }
      );
  }

  selectedSize(value): void {
    this.getTotalColor();
  }

  //#region Grid actions
  // grid: create empty row with combo box
  createEmptyRow() {
    let itemEmptyRow = {
      stockItemID: "dropdown",
      sku: "",
      description: "",
      cartonQty: 0,
      cbm: 0,
      taxRate: 0,
      weightPerItem: 0,
      weight: 0,
      unitCost: 0,
      quantity: 0,
      totalCBM: 0,
      lineTotal: 0,
      hide: true,
      factoryItemNo: ""
    };

    let itemsToUpdate = [];
    itemsToUpdate.push(itemEmptyRow);
    if (this.rowData == null) this.rowData = [];
    this.rowData.push(itemEmptyRow);

    this.gridApi.applyTransaction({ add: itemsToUpdate });
    this.gridApi.redrawRows();
  }

  // grid init
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.createEmptyRow();
    this.getTotalRow();
  }

  tabToNextCell(params) {
    var previousCell = params.previousCellPosition,
      lastRowIndex = previousCell.rowIndex,
      nextRowIndex = params.backwards ? lastRowIndex - 1 : lastRowIndex + 1,
      renderedRowCount = params.previousCellPosition.column.gridApi.getModel().getRowCount(),
      result;
    if (nextRowIndex < 0) {
      nextRowIndex = -1;
    }
    if (nextRowIndex >= renderedRowCount) {
      nextRowIndex = renderedRowCount - 1;
    }
    result = {
      rowIndex: nextRowIndex,
      column: previousCell.column,
      floating: previousCell.floating,
    };

    // if (params.data.stockItemID != "dropdown") {
    //   this.updatePurchaseItem(params.data);
    //   this.getTotalRow();
    // }
    return result;
  }

  onRowDataUpdated(params) {
    this.getTotalRow();
  }

  // grid: editing values - quantity and unitprice
  onCellValueChanged(event): void {
    
    if (event.data.stockItemID != "dropdown") {
      this.updatePurchaseItem(event.data);
      this.getTotalRow();
    }
    let comboItemSku = event.data.sku.split(" ")[0];

    this.rowData.forEach((item, index) => {
      if (item.sku !== event.data.sku && item.sku.startsWith(comboItemSku) && !this.disableBoxQtyAlign) {
        item.quantity = event.data.quantity;
        this.updatePurchaseItem(item);
      }
    });
    this.gridApi.refreshCells();
    // this.gridApi.redrawRows();
  }

  // grid: update purchase item data
  updatePurchaseItem(newItem: OrderItem) {
    if (newItem.quantity < 0 || newItem.unitCost < 0) {
      this.snackBar.error("Wrong value");

      newItem.quantity = newItem.cartonQty;
    } else {
      if (newItem.quantity % newItem.cartonQty != 0) {
        newItem.quantity =
          Math.ceil(newItem.quantity / newItem.cartonQty) * newItem.cartonQty;
        // newItem.quantity = newItem.cartonQty;

        this.snackBar.neutral(
          "Quantity has been rounded up to comply with full carton quantity",
          newItem.cartonQty.toString()
        );
      }

      this.recalculateValues(newItem);
    }
  }

  recalculateValues(newItem: OrderItem): void {
    var oldItem = this.rowData.filter(function (item) {
      return item.stockItemID == newItem.stockItemID;
    })[0];

    if (oldItem != null) {
      var item = this.stockItems.filter(function (item) {
        return item.stockItemID == newItem.stockItemID;
      })[0];

      newItem.totalCBM = parseFloat(
        (
          (parseInt(newItem.quantity.toString(), 0) /
            parseInt(newItem.cartonQty.toString(), 0)) *
          parseFloat(item.cbm.toString())
        ).toFixed(4)
      );
      newItem.unitCost = parseFloat(
        parseFloat(newItem.unitCost.toString()).toFixed(2)
      );

      newItem.lineTotal = this.roundNumber(
        parseInt(newItem.quantity.toString(), 0) *
          parseFloat(newItem.unitCost.toString())
      );

      // recalc weight
      // var weightPerItemKg = parseFloat(item.weight.toString()) / 1000;
      // newItem.weight = parseFloat(
      //   (weightPerItemKg * parseFloat(newItem.quantity.toString()))
      //     .toFixed(2)
      //     .toString()
      // ); // in g
      newItem.weight = item.weight;

      // IF NaN then 0
      newItem.totalCBM = isNaN(newItem.totalCBM) ? 0 : newItem.totalCBM;
      newItem.lineTotal = isNaN(newItem.lineTotal) ? 0 : newItem.lineTotal;
      newItem.weight = isNaN(newItem.weight) ? 0 : newItem.weight;

      oldItem = newItem;

      this.gridApi.applyTransaction({ update: [oldItem] });
    }
  }

  // grid: add total row
  getTotalRow() {
    if (this.rowData != null && this.rowData.length > 0) {
      this.totalQty = this.rowData.reduce(
        (prev, cur) => prev + parseInt(cur.quantity.toString(), 0),
        0
      );
      this.totalCBM = this.rowData
        .reduce((prev, cur) => prev + cur.totalCBM, 0)
        .toFixed(4);
      this.totalLine = this.roundNumber(
        this.rowData.reduce((prev, cur) => prev + cur.lineTotal, 0)
      ).toFixed(2);
      this.totalWeight = parseFloat(
        this.rowData
          .reduce((prev, cur) => prev + cur.weight * cur.quantity, 0)
          .toFixed(2)
      );
    } else {
      this.totalQty = 0;
      this.totalCBM = 0;
      this.totalLine = 0;
      this.totalWeight = 0;
      this.totalWeightFloat = 0;
    }

    this.getTotalColor();
  }

  getTotalColor() {
    if (
      this.selectedContainerSize == "20 FT" &&
      this.totalWeightFloat > 24000
    ) {
      this.isContainerSizeBigger = true;
    } else if (
      this.selectedContainerSize == "40 FT" &&
      this.totalWeightFloat > 25000
    ) {
      this.isContainerSizeBigger = true;
    } else if (
      this.selectedContainerSize == "40 FT HQ" &&
      this.totalWeightFloat > 25000
    ) {
      this.isContainerSizeBigger = true;
    } else {
      this.isContainerSizeBigger = false;
    }
  }

  // grid: remove items
  clearGrid(): void {
    var itemsToUpdate = [];

    this.gridApi.setRowData(itemsToUpdate);
    this.gridApi.redrawRows();
  }

  //#endregion

  calculateDates() {
    const transitTime = this.selectedPOL.transitTime > 0 ? this.selectedPOL.transitTime : this.settings.intervalShippingAndDocking;
    this.shippingDate = this.addDays(this.goodsReadyDate, this.settings.intervalGoodsReadyAndShipping);
    this.dockingDate = this.addDays(this.shippingDate, transitTime);
    this.expectedDeliverySlot = this.addDays(this.dockingDate, this.settings.intervalDockingAndExpectedDelivery);
  }

  private addDays(date, days) {
    const copy = new Date(Number(date));
    copy.setDate(date.getDate() + days);
    return copy;
  }
  //#region PO Actions

  // btn: creating new purchase order
  createPO(): void {
    this.gridApi.stopEditing();
    setTimeout(() => {
      if (this.rowData != null && this.rowData.length > 1) {
        this.loadingData = true;
        var purchase = new Purchase();
        purchase.locationID = "00000000-0000-0000-0000-000000000000";
        purchase.supplierID = this.selectedSupplier.pkSupplierID;
        purchase.containerSize = this.selectedContainerSize;
        purchase.conversionRate = isNaN(this.conversionRate)
          ? 1
          : this.conversionRate;
        purchase.reference =
          this.referenceOfPO == null ? " " : this.referenceOfPO;
        purchase.reference2 =
          this.referenceOfPO2 == null ? " " : this.referenceOfPO2;

        purchase.supplierInvoiceNumber = this.suppInvNo == null ? " " : this.suppInvNo;

        purchase.orderDate = this.dateOrder;
        purchase.goodsReadyDate = moment(this.goodsReadyDate).format(
          "DD/MM/YY"
        );

        purchase.shippingDate = moment(this.shippingDate).format("DD/MM/YY");
        purchase.dockingDate = moment(this.dockingDate).format("DD/MM/YY");
        purchase.expectedDeliverySlot = moment(
          this.expectedDeliverySlot
        ).format("DD/MM/YY");

        purchase.port = this.selectedPOL.name;

        purchase.orderNumber = this.poNumber.toString();

        purchase.status = this.statuses.indexOf(this.selectedStatus);
        if (purchase.status == 2) purchase.status = purchase.status + 1; // Delivered, not partial

        var newItems = [];

        this.rowData.forEach((item, index) => {
          if (item.stockItemID != "total" && item.stockItemID != "dropdown") {
            var newItem = new PurchaseItem();

            newItem.stockItemID = item.stockItemID;
            newItem.quantity = item.quantity; // == null ? 1 : parseInt(item.quantity.toString(), 1);
            newItem.ctnqty = item.cartonQty; // == null ? 1 : parseInt(item.cartonQty.toString(), 1);
            newItem.cost = item.lineTotal;

            newItem.packSize = 1;
            newItem.weight = item.weight;
            newItem.taxRate = 0;

            newItems.push(newItem);
          }
        });

        purchase.items = newItems;
        this.loadingData = true;

        this.repo.create("api/po/createPurchaseOrder", purchase).subscribe(
          (result: any) => {
            this.loadingData = false;

            this.snackBar.success("Succesfully created");

            this.clearFields();

            this.poService.currentPurchase = result.pkPurchaseID;
            this.poService.returnUrl = "/po-list";
            // this.router.navigate(["/po-wrapper/po-view"]);
            this.poService.purchaseOrderCreatedSubject.next(true);
            this.appService.closePurchaseTab(this.appService.currentTab);
            this.router.navigate(["/home/po-view"], { queryParams: { componentIndex: result.externalInvoiceNumber } });
          },
          (error) => {
            this.loadingData = false;
            this.snackBar.error("Something went wrong");
          }
        );

        // this.getNewPONumber();
      } else {
        this.snackBar.warning("Please, add items to PO.");
      }
    }, 500);
  }

  //#endregion

  roundNumber(value: number): number {
    return Math.round(value * 100) / 100;
  }

  // clear all after creating new order and generate new number
  clearFields(): void {
    this.poNumber = "";
    // this.getNewPONumber();

    this.dateOrder = new Date();
    this.goodsReadyDate = new Date();

    this.referenceOfPO = "";
    this.referenceOfPO2 = "";
    this.suppInvNo = "";

    this.rowData = [];
    this.gridApi.setRowData(this.rowData);
    this.gridApi.redrawRows();

    //recalc total and add dropdown back
    this.createEmptyRow();
    this.getTotalRow();
  }
}

function digitFormatter(params) {
  if (isNaN(params.value) || params.data.stockItemID === "dropdown") {
    return "";
  }
  return parseFloat(params.value)
    .toFixed(2)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}
