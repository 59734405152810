<div class="procurement-container d-flex-column">
    <div class="d-flex-vert-center gap-8">
        <mat-form-field class="selectSupplier">
            <mat-label>Supplier:</mat-label>
            <mat-select [(value)]="selectedSupplier"
                (selectionChange)="selectedSupplierChange($event.value)"
                [disabled]="loadingData">
                <mat-option *ngFor="let supp of suppliers"
                    [value]="supp">
                    {{supp.supplierName}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="selectSupplier">
            <mat-label>Category:</mat-label>
            <mat-select [(value)]="selectedCategory"
                (selectionChange)="selectedCategoryChange($event.value)"
                [disabled]="loadingData">
                <mat-option *ngFor="let cat of categories"
                    [value]="cat">
                    {{cat.categoryName}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="search-field">
            <input matInput
                placeholder="Search"
                aria-label="State"
                [matAutocomplete]="auto"
                [formControl]="searchItemsCtrl"
                [(ngModel)]="searchItemField">
            <mat-autocomplete #auto="matAutocomplete"
                (optionSelected)="selectedFiltereditemChange($event.option); searchItemField = '';">
                <mat-option *ngIf="loadingData"
                    class="is-loading">Loading...</mat-option>
                <ng-container *ngIf="!loadingData">
                    <mat-option *ngFor="let item of filteredItems"
                        [value]="item.sku">
                        <span><b>{{item.sku}}</b> ({{item.title}})</span>
                    </mat-option>
                </ng-container>
            </mat-autocomplete>
        </mat-form-field>

        <mat-slide-toggle *ngIf="procurementData?.length"
            [(ngModel)]="shippedOnly"
            (ngModelChange)="onlyShippedChange()">Only Shipped</mat-slide-toggle>

        <div style="display: inline-flex;float: right;"
            *ngIf="procurementData?.length">
            <mat-paginator [length]="procurementData.length"
                [pageSize]="pageSize"
                [pageSizeOptions]="[5, 10, 20]"
                (page)="pageEvent = OnPageChange($event)">
            </mat-paginator>
        </div>
    </div>

    <!-- Table -->
    <mat-card *ngIf="procurementData && procurementData.length > 0"
        class="procur-mat-card">
        <div class="accordion-container">

            <mat-accordion *ngIf="procurementData && procurementData.length > 0"
                multi>

                <mat-expansion-panel #panel="matExpansionPanel"
                    *ngFor="let item of pagedList; let i = index"
                    (opened)="item.isDeleted = false"
                    (closed)="item.isDeleted = true"
                    [expanded]="!item.discontinued"
                    hideToggle
                    (click)="preventCollapse($event, panel);selectSKU(i)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span>{{item.sku}}</span>

                            <div class="image-holder mg-l16"
                                *ngIf="item.primaryImage">
                                <img [src]="item.primaryImage"
                                [appImageTooltip]="item.primaryImage"/>
                            </div>

                            <button *ngIf="panel.expanded"
                                mat-raised-button
                                (click)="markAsDiscontinued($event, item)">
                                <div *ngIf="!item.discontinued">Set Discontinued</div>
                                <div *ngIf="item.discontinued">Set Active</div>
                            </button>
                        </mat-panel-title>
                        
                        <mat-panel-description style="justify-content: left !important;" class="d-flex-row gap-16 mg-l24">
                        {{item.title}}
                        <div class="d-flex-column">
                            <span>Stock level:</span>
                            <span>{{item.stockLevel}}</span>
                        </div>
                        <div class="d-flex-column">
                            <span>On order:</span>
                            <span>{{item.stockOnOrder}}</span>
                        </div>
                        <div class="d-flex-column">
                            <span>Yearly sales:</span>
                            <span>{{item.yearlySales}}</span>
                        </div>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="table-container mat-elevation-z2">

                        <div class="row"
                            style="width:max-content">

                            <table class="main-table"
                                mat-table
                                [dataSource]="item.details"
                                class="mat-elevation-z0">

                                <ng-container [matColumnDef]="column.name"
                                    *ngFor="let column of displayedColumns">

                                    <div *ngIf="column.name === 'description'; else commonHeaderCell">
                                        <th mat-header-cell
                                            *matHeaderCellDef>
                                        </th>
                                    </div>
                                    <ng-template #commonHeaderCell>
                                        <th mat-header-cell
                                            *matHeaderCellDef> {{column.displayedName}} </th>
                                    </ng-template>
                                    <td mat-cell
                                        *matCellDef="let element"> {{element[column.name]}} </td>
                                </ng-container>

                                <tr mat-header-row
                                    *matHeaderRowDef="columnsToDisplay"></tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: columnsToDisplay;"
                                    class="table-row"></tr>
                            </table>

                            <table class="details-table"
                                mat-table
                                [dataSource]="item.details"
                                class="mat-elevation-z0">

                                <ng-container [matColumnDef]="column.name"
                                    *ngFor="let column of columnsForecast;let y = index">
                                    <th mat-header-cell
                                        *matHeaderCellDef
                                        (click)=selectColumn(i,y)
                                        [ngClass]="selectedColumn[y] ? 'selectedColumn' : 'unselected'">
                                        {{column.displayedName}} </th>

                                    <td [ngClass]="{
                                      'low-inventory':item.description === 'Inventory' && item.weekInfo[column.name] <= 0,
                                      'low-inventory':item.description === 'Inventory' && item.discontinued === true,
                                      'inv-changed':item.description === 'Inventory' && procurementData[i].details[2].weekInfo[column.name] !== procurementDataOrigin[i].details[2].weekInfo[column.name] && procurementData[i].details[2].weekInfo[column.name] > 0,
                                      'intake-arrive':item.description === 'Intake' && item.weekInfo[column.name] > 0,
                                      'intake-yellow':item.description === 'Intake' && item.weekInfo[column.name] > 0 && item.detailStatus[column.name] === 1,
                                      'intake-red':item.description === 'Intake' && item.weekInfo[column.name] > 0 && item.detailStatus[column.name] === 2,
                                      'intake-3':item.description === 'Intake' && item.weekInfo[column.name] > 0 && item.detailStatus[column.name] === 3,
                                      'intake-4':item.description === 'Intake' && item.weekInfo[column.name] > 0 && item.detailStatus[column.name] === 4,
                                      'intake-5':item.description === 'Intake' && item.weekInfo[column.name] > 0 && item.detailStatus[column.name] === 5,
                                      'intake-6':item.description === 'Intake' && item.weekInfo[column.name] > 0 && item.detailStatus[column.name] === 6,
                                      'value-changed':item.description === 'Outcome' && procurementData[i].details[6].weekInfo[column.name] !== procurementDataOrigin[i].details[6].weekInfo[column.name],
                                      'value-changed-r':item.description === 'Outcome' && procurementData[i].details[6].weekInfo[column.name] !== procurementDataOrigin[i].details[6].weekInfo[column.name] && procurementData[i].details[6].weekInfo[column.name] == 0,
                                      'value-changed-z':item.description === 'Last 8 Week' && procurementData[i].details[0].weekInfo[column.name] !== procurementDataOrigin[i].details[0].weekInfo[column.name],
                                      'value-changed-f':item.description === 'Suggestion' && procurementData[i].details[5].weekInfo[column.name] !== procurementDataOrigin[i].details[5].weekInfo[column.name],
                                      'value-changed-fc':item.description === 'Forecast' && procurementData[i].details[1].weekInfo[column.name] !== procurementDataOrigin[i].details[1].weekInfo[column.name],
                                      'low-inventory':item.description === 'Inventory' && item.weekInfo[column.name] <= 0,
                                      'low-inventory': item.description === 'Outcome Cover' && pagedList[i].details[7].weekInfo[column.name] === 0
                                    }"
                                        mat-cell
                                        *matCellDef="let item"
                                        (click)="item.description === 'Intake' && getIntake(i, y, column.name, item.weekInfo[column.name], $event)">
                                        <mat-form-field class="table-editable-cell"
                                            *ngIf="item.description === 'Forecast' || item.description === 'Suggestion'; else elseBlock"
                                            class="editable-cell"
                                            appearance="0"
                                            floatLabel="never">
                                            <input
                                                (click)="item.weekInfo[column.name] === 0 ? item.weekInfo[column.name]='' : ''"
                                                (change)="onCellValueChange(i, y, column.name, item.weekInfo[column.name], item.description);item.description === 'Forecast' ? updateForecast(column.date, item.weekInfo[column.name], y) : null"
                                                (keyup)="item.description === 'Forecast' ? updateForecast(column.date, item.weekInfo[column.name], y) : null"
                                                matInput
                                                type="number"
                                                [value]="item.weekInfo[column.name]"
                                                [min]="0"
                                                [(ngModel)]="item.weekInfo[column.name]">
                                        </mat-form-field>
                                        <ng-template #elseBlock>
                                            <div
                                                *ngIf="item.description === 'Intake' && item.forecastQty[column.name] > 1; else elseElseBlock">
                                                {{item.weekInfo[column.name]}}
                                                <sup>{{item.forecastQty[column.name]}}</sup>
                                            </div>
                                            <ng-template #elseElseBlock>{{item.weekInfo[column.name]}}</ng-template>
                                        </ng-template>
                                    </td>
                                </ng-container>
                                <tr mat-header-row
                                    *matHeaderRowDef="columnsForecastToDisplay"></tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: columnsForecastToDisplay;"
                                    class="table-row">
                                </tr>
                            </table>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </mat-card>

    <div *ngIf="selectedItem.index > -1">
        <mat-grid-list cols="8"
            rowHeight="4:1">
            <mat-grid-tile>
                <p>SKU: {{selectedItem.item.sku}}</p>
            </mat-grid-tile>
            <mat-grid-tile>
                <p>CBM: {{selectedItem.item.cbm}}</p>
            </mat-grid-tile>
            <mat-grid-tile>
                <p>CTN QTY: {{selectedItem.item.cartonQty}}</p>
            </mat-grid-tile>
            <mat-grid-tile>
                <p>Week CBM: {{weekPlannedCBM}}</p>
            </mat-grid-tile>
            <mat-grid-tile>
                <p>Week Qty: {{weekPlannedQty}}</p>
            </mat-grid-tile>
            <mat-grid-tile></mat-grid-tile>
            <mat-grid-tile></mat-grid-tile>
            <mat-grid-tile></mat-grid-tile>
        </mat-grid-list>

        <mat-chip-list *ngIf="weeklySuggestions?.length"
            #weeklySuggestionsChipList>
            <mat-chip color="primary"
                selected
                *ngFor="let ws of weeklySuggestions;let i = index"
                (removed)="removeWeeklySuggestion(ws)"
                (click)="createPurchaseFromWeeklySuggestion(i)">
                #{{ws.weekIndex}} - {{ws.weekStart | date:'dd/MM/yy'}} - Total Qty:
                {{getWeeklySuggestionsSummary(i)[0]}} -
                Total CBM:
                {{getWeeklySuggestionsSummary(i)[1] | number : '1.2-4'}}
                <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip>
        </mat-chip-list>

        <div class="d-flex-sp-btw mg-t8">
            <div class="d-flex-vert-center gap-4">
                <button mat-raised-button
                    (click)="getLastWeekSales()"
                    class="procur-button">Last
                    Sales</button>
                <button mat-raised-button
                    (click)="openExtendedPropsEditDialog()"
                    class="procur-button">Edit
                    Props</button>
                <button mat-raised-button
                    (click)="openForecastOverrideDialog()"
                    class="procur-button">Override
                    Forecast</button>
                <button mat-raised-button
                    (click)="clearOutcome()"
                    class="procur-button">Clear
                    Outcome</button>
                <!-- <button mat-raised-button (click)="openTimePeriodForecast()" class="procur-button">Time
                Period</button> -->
                <button mat-raised-button
                    (click)="openPurchaseOrderDialog()"
                    class="procur-button">Generate
                    PO</button>

                <!-- <button mat-raised-button (click)="markAsDiscontinued()" class="procur-button">Mark as Discontinued</button> -->
            </div>

            <div class="d-flex-vert-center gap-4">
                <div class="common-intake"> container(s) arrives that week</div>
                <div class="booked"> booked</div>
                <div class="shipped"> shipped</div>
                <!-- <div class="notShipped"> not shipped</div> -->
            </div>
        </div>
    </div>
</div>