import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StockItem } from 'src/app/shared/models/purchase.models';
@Injectable({
    providedIn: 'root'
})
export class POItemsBySupplierService {
    counter = 1;
    count: BehaviorSubject<number>;
    stockItems: StockItem[];
    containerSizes: string[] = ["N/A", "20 FT", "40 FT", "40 FT HQ"];
    selectedContainerSize: string;
    currentStatus: string;
    procurementSupplierId: string;
    procurementPurchaseItems: object[] = [];
    purchaseDate: Date;

    currentTab: string;

    public closeTab$: Observable<string>;
    private closeTab: BehaviorSubject<string>;

    constructor() {
        this.stockItems = [];
        this.count = new BehaviorSubject(this.counter);
        this.selectedContainerSize = this.containerSizes[2];
        this.purchaseDate = new Date();

        this.closeTab = new BehaviorSubject<string>(null);
        this.closeTab$ = this.closeTab.asObservable();
    }

    nextCount() {
        this.count.next(++this.counter);
    }

    public closePurchaseTab(tab: string): void {
        this.closeTab.next(tab);
    }
}
