import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(
    @Inject("BASE_URL") private baseUrl: string,
    private http: HttpClient
  ) {}

  getOrders(route: string): Observable<any> {
    return this.http.get<any>("api/stream/" + route);
  }

  getOrderDetails(id: string): Observable<Object> {
    return this.http.get('api/stream/orderDetails/' + id);
  }

  getRuns(): Observable<Object> {
    return this.http.get('api/stream/runs');
  }

  sendEmailsToCustomers(email: any): Observable<Object> {
    return this.http.post('api/stream/sendEmails', email);
  }
}
