import { Location } from "./../models/purchase.models";
import {
  PagedList,
  StockValueModel,
  UpdateStockItemPrice,
  View,
} from "./../models/product-screen.models";
import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Column } from "../models/product-screen.models";
import {
  EditInventoryItemTitle,
  NewInventoryItem,
} from "../models/newInventoryItem";
import { Supplier } from "../models/Supplier";
import { Category } from "../models/category";
import { StockItemSupplierInfo } from "../models/StockItemSupplierInfo";
import { Channel } from 'src/app/shared/models/channel';
@Injectable({
  providedIn: "root",
})
export class InventoryService {
  constructor(
    @Inject("BASE_URL") private baseUrl: string,
    private http: HttpClient
  ) {}

  // General

  getAvailableSKUforNewItem() {
    return this.http.get<any>("api/inventory/getAvailableSKUforNewItem");
  }

  addNewItem(newItem: NewInventoryItem) {
    return this.http.post<NewInventoryItem>(
      "api/inventory/addNewItem",
      newItem
    );
  }

  editItemTitle(editedItem: EditInventoryItemTitle) {
    return this.http.post<EditInventoryItemTitle>(
      "api/inventory/editItemTitle",
      editedItem
    );
  }

  getStockItemFullInfoById(stockItemId: string) {
    return this.http.get(
      `api/inventory/getStockItemFullInfoById/${stockItemId}`
    );
  }

  getItemTitleByid(stockItemId: string) {
    return this.http.get(
      `api/inventory/getInventoryItemTitleById/${stockItemId}`
    );
  }

  getParentsByChilds(childIds: string[]) {
    return this.http.post<string[]>(
      "api/inventory/getParentsByChildsIds",
      childIds
    );
  }

  getSpecSheetInfoById(stockItemId: string) {
    return this.http.get(`api/inventory/getSpecSheetInfoById/${stockItemId}`);
  }

  getSuppliers() {
    return this.http.get<any>(`api/inventory/suppliers/`);
  }

  private suppliersForProductScreenURL =
    "api/inventory/getSuppliersForProductScreen";
  getSuppliersForProductScreen() {
    return this.http.get<any>(this.suppliersForProductScreenURL);
  }

  getCategoriesBySupplierId(supplierId: string) {
    return this.http.get<any>(`api/inventory/categories/${supplierId}`);
  }

  // Extended Properties
  getStockItemPropsById(stockItemId: string) {
    return this.http.get<any[]>(
      `api/inventory/getStockItemPropsById/${stockItemId}`
    );
  }

  updateStockItemProps(extendedProps) {
    return this.http.put("api/inventory/updateStockItemProps", extendedProps);
  }

  // Spec Sheet
  getSpecSheet(id: string, poNumber: string) {
    return this.http.get<Blob>(`api/inventory/specsheet/${id}/${poNumber}`, {
      responseType: "blob" as "json",
      observe: "response",
    });
  }

  private getCategoriesURL = "api/inventory/categories";
  getCategories() {
    return this.http.get<Category[]>(this.baseUrl + this.getCategoriesURL);
  }

  private getProductScreenColumnsURL = "api/inventory/getProductScreenColumns";
  getProductScreenColumns() {
    return this.http.get<Column[]>(
      this.baseUrl + this.getProductScreenColumnsURL
    );
  }

  private editOrCreateViewURL = "api/inventory/editOrCreateView";
  editOrCreateView(view: View) {
    return this.http.post<any>(this.editOrCreateViewURL, view);
  }

  private getAllViewsURL = "api/inventory/getAllViews";
  getAllViews() {
    return this.http.get<View[]>(this.baseUrl + this.getAllViewsURL);
  }

  private deleteViewURL = "api/inventory/deleteView";
  deleteView(viewId: number) {
    return this.http.delete(this.deleteViewURL + `?id=${viewId}`);
  }

  private getStockItemExtendedPropertyByPropertyNameURL =
    "api/inventory/getStockItemExtendedPropertyByPropertyName";
  getStockItemExtendedPropertyByPropertyName(
    stockItemId: string,
    propertyName: string
  ) {
    return this.http.get<any>(
      this.baseUrl +
        this.getStockItemExtendedPropertyByPropertyNameURL +
        `?stockItemId=${stockItemId}&propertyName=${propertyName}`
    );
  }

  private updatetStockItemExtendedPropertyURL =
    "api/inventory/updatetStockItemExtendedProperty";
  updatetStockItemExtendedProperty(stockItemExtendedProperty) {
    return this.http.put(
      this.baseUrl + this.updatetStockItemExtendedPropertyURL,
      stockItemExtendedProperty
    );
  }

  public updateSupplierData(model: StockItemSupplierInfo) {
    return this.http.post(
      this.baseUrl + "api/inventory/updateSupplierData",
      model
    );
  }

  private getProductScreenItemsURL = "api/inventory/getProductScreenItems";
  getProductScreenItems(
    viewId: number,
    pageNumber: number,
    pageSize: number,
    sortColumn?: string,
    sortDirection?: string,
    supplierId?: string,
    categoryId?: string,
    locationId?: string,
    search?: string,
    includeFiltering: boolean = true,
    showCombos: boolean = false
  ) {
    return this.http.get<PagedList>(
      `${this.baseUrl}${this.getProductScreenItemsURL}?viewId=${viewId}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&supplierId=${supplierId}&categoryId=${categoryId}&locationId=${locationId}&search=${search}&includeFiltering=${includeFiltering}&showCombos=${showCombos}`
    );
  }

  updateWooPrice(wooId: string, price: string) {
    return this.http.put(`${this.baseUrl}api/inventory/updateWooPrice?wooId=${wooId}&price=${price}`, {})
  }

  private getProductScreenItemsCompositesURL =
    "api/inventory/getProductScreenItemsComposites";
  getProductScreenItemsComposites(
    viewId: number,
    pageNumber: number,
    pageSize: number,
    compositesIds: string[],
    sortColumn?: string,
    sortDirection?: string
  ) {
    return this.http.get<any>(
      `${this.baseUrl}${this.getProductScreenItemsCompositesURL}?viewId=${viewId}&pageNumber=${pageNumber}&pageSize=${pageSize}&compositesIds=${compositesIds}&sortColumn=${sortColumn}&sortDirection=${sortDirection}`
    );
  }

  private getStockLocationsURL = "api/inventory/getStockLocations";
  getStockLocations() {
    return this.http.get<Location[]>(this.baseUrl + this.getStockLocationsURL);
  }

  private setItemAsDiscontinuedURL = "api/inventory/setItemAsDiscontinued";
  setItemAsDiscontinued(stockItemId: string) {
    return this.http.post<any>(
      `${this.setItemAsDiscontinuedURL}?stockItemId=${stockItemId}`,
      {}
    );
  }

  private duplicateInventoryItemURL = "api/inventory/duplicateInventoryItem";
  duplicateInventoryItem(sourceItemId: string) {
    return this.http.post<any>(
      `${this.duplicateInventoryItemURL}?sourceItemId=${sourceItemId}`,
      {}
    );
  }

  private setDefaultSupplierForInventoryItemURL =
    "api/inventory/setDefaultSupplierForInventoryItem";
  setDefaultSupplierForInventoryItem(stockItemId: string, supplierId: string) {
    return this.http.put<any>(
      `${this.setDefaultSupplierForInventoryItemURL}?stockItemId=${stockItemId}&supplierId=${supplierId}`,
      {}
    );
  }

  private getStockItemSuppliersURL = "api/inventory/getStockItemSuppliers";
  getStockItemSuppliers(stockItemId: string) {
    return this.http.get<Supplier[]>(
      this.getStockItemSuppliersURL + `?stockItemId=${stockItemId}`
    );
  }

  private changeFactoryPriceURL = "api/inventory/changeFactoryPrice";
  changeFactoryPrice(stockItemId: string, price: number) {
    return this.http.put<any>(
      `${this.changeFactoryPriceURL}?stockItemId=${stockItemId}&price=${price}`,
      {}
    );
  }

  private changeStockValueURL = "api/inventory/changeStockValue";
  ChangeStockValue(model: StockValueModel) {
    return this.http.put<any>(this.changeStockValueURL, model);
  }

  private resetStockValueURL = "api/inventory/resetStockValue";
  ResetStockValue(stockItemId: string) {
    return this.http.post<any>(
      this.resetStockValueURL + `?stockItemId=${stockItemId}`,
      {}
    );
  }

  private getPurchaseIdsByStockItemIdURL =
    "api/inventory/getPurchaseIdsByStockItemId";
  GetPurchaseIdsByStockItemId(stockItemId: string) {
    return this.http.get<any>(
      this.baseUrl +
        this.getPurchaseIdsByStockItemIdURL +
        `?stockItemId=${stockItemId}`
    );
  }

  private updateInventoryCategoryURL = "api/inventory/updateInventoryCategory";
  UpdateInventoryCategory(stockItemId: string, category: Category) {
    return this.http.put(
      this.updateInventoryCategoryURL + `?stockItemId=${stockItemId}`,
      category,
      { observe: "response" }
    );
  }

  private deleteInventoryItemURL = "api/inventory/deleteInventoryItem";
  DeleteInventoryItem(inventoryItemId: string) {
    return this.http.delete(
      this.deleteInventoryItemURL + `?inventoryItemId=${inventoryItemId}`
    );
  }

  private addSupplierToInventoryItemURL =
    "api/inventory/addSupplierToInventoryItem";
  AddSupplierToInventoryItem(stockItemId: string, supplierId: string) {
    return this.http.post<any>(
      `${this.addSupplierToInventoryItemURL}?stockItemId=${stockItemId}&supplierId=${supplierId}`,
      {}
    );
  }

  private changeSupplierStateURL = "api/inventory/changeSupplierState";
  ChangeSupplierState(supplierId: string, active: boolean, portId: number = 0) {
    return this.http.post<any>(
      `${this.changeSupplierStateURL}?supplierId=${supplierId}&active=${active}&portId=${portId}`,
      {}
    );
  }

  private getInventoryItemPricesURL = "api/inventory/getInventoryItemPrices";
  GetInventoryItemPrices(inventoryItemId: string) {
    return this.http.get<any>(
      this.baseUrl +
        this.getInventoryItemPricesURL +
        `?inventoryItemId=${inventoryItemId}`
    );
  }

  private updateInventoryItemPricesURL =
    "api/inventory/updateInventoryItemPrice";
  updateChannelPrice(model: UpdateStockItemPrice) {
    return this.http.put(this.updateInventoryItemPricesURL, model, {
      observe: "response",
    });
  }

  copyPriceToAllChannels(stockItemId, price) {
    return this.http.put(`api/inventory/copyPriceToAllChannels?stockItemId=${stockItemId}&price=${price}`, {});
  }

  updatePriceByAmount(stockItemIds: string[], amount: number) {
    return this.http.put(`api/inventory/updatePriceByAmount?amount=${amount}`, {stockItemIds: stockItemIds});
  }

  public searchStockItems(key: string) {
    return this.http.get(`api/inventory/getStockItemsByKey/${key}`);
  }

  public searchStockITemsByKey(key: string) {
    return this.http.get(`api/inventory/searchStockItemsByKey/${key}`);
  }

  public getChannels() {
    return this.http.get<Channel[]>(`api/inventory/channels`);
  }

  public getAllChannels() {
    return this.http.get<Channel[]>(`api/inventory/allChannels`);
  }

  public getComboItemsWithChannelPrices(stockItemId: string, source: string, subsource: string, tag?: string) {
    return this.http.get(`api/inventory/comboItemsWithChannelPrices?stockItemId=${stockItemId}&source=${source}&subsource=${subsource}&tag=${tag}`);
  }

  public getLinkedComboMainPartIds(stockItemId: string) {
    return this.http.get<string[]>(`api/inventory/linkedComboMainPartIds/${stockItemId}`);
  }

  public updateChannelPrices(prices: any[]) {
    return this.http.put(`api/inventory/updateChannelPrices`, prices);
  }

  // <!------------- not sortable props for PRODUCT SCREEN ---------------!>
  notSortablePropsProductScreen: string[] = [
    "LandedCost",
    "LandedCostStockValue",
    "white-background",
    "white-background-with-size",
    "lifestyle",
    "closeup",
    "highres",
    "packaging",
    "specsheet",
    "infographics",
    "PrimaryImage",
    "photos",
    "instructions",
    "videos",
    "a-plus-content"
  ];

  numberColumnsProductScreen: string[] = [
    'FactoryPrice',
    'StockLevel',
    'Due',
    'LandedCost',
    'Cbm',
    'CtnQty',
    'StockOnOrder',
    'InOpenOrders',
    'Sales',
    'Available',
    'LandedCostStockValue',
    'Price',
    'QuantityInOpenOrders',
    'QuantityInPaidOpenOrders',
    'WooCommerceSalePrice',
  ];
}
