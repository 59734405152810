import { Component, OnInit } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColumnApi, GridApi, GridOptions } from "ag-grid-community";
import { StockItemSupplierInfo } from 'src/app/shared/models/StockItemSupplierInfo';
import { InventoryService } from 'src/app/shared/services/inventory.service';
import { SupplierInfoService } from 'src/app/shared/services/supplier-info.service';
import { StockItemSupplierInfoService } from 'src/app/shared/services/stockItemSupplierInfo.service';
import { mergeMap, tap } from 'rxjs';

@Component({
  selector: 'app-stock-item-suppliers',
  templateUrl: './stock-item-suppliers.component.html',
  styleUrls: ['./stock-item-suppliers.component.scss']
})
export class StockItemSuppliersComponent implements OnInit {


  private gridApi;
  private gridColumnApi;
  public columnDefs;
  public defaultColDef;
  public defaultColGroupDef;
  public columnTypes;
  public rowData = [];
  public stockItemId: string;
  public showSupplierSelect: boolean;

  public suppliers: any[];

  constructor(
    private inventoryService: InventoryService,
    private stockItemSupplierInfoService: StockItemSupplierInfoService,
  ) {
    this.columnDefs = [
      {
        headerName: "Default",
        field: "isDefault",
        width: 95,
      },
      {
        headerName: "Name",
        field: "supplierName",
        width: 120,
        tooltipField: "supplierName",
      },
      {
        headerName: "Purchase Price USD",
        field: "purchasePriceUSD",
        width: 160,
        editable: true,
      },
      {
        headerName: "CBM",
        field: "cbm",
        width: 80,
        editable: true,
      },
      {
        headerName: "CTN QTY",
        field: "cartonQuantity",
        width: 100,
        editable: true,
      },
      {
        headerName: "POL",
        field: "pol",
        width: 100,
        editable: true,
        tooltipField: "pol",
      },
    ];

    this.defaultColDef = {
      width: 120,
      sortable: true,
      filter: "agTextColumnFilter",
      resizable: true,
    };
  }

  ngOnInit(): void {
    this.stockItemSupplierInfoService.getSupplierInfosById(this.stockItemId)
    .pipe(
      tap((result: any) => {
        this.rowData = result;
      })
    )
    .subscribe();
    this.getSuppliers();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  saveSelected(): void {
    if (!this.gridApi.getSelectedRows()) return;
    const selectedItem = this.gridApi.getSelectedRows()[0];
    this.stockItemSupplierInfoService.updateInfo(this.stockItemId, selectedItem.supplierId, selectedItem)
    .pipe(
      tap(() => {
        
      })
    ).subscribe();
  }

  deleteSelected(): void {
    if (!this.gridApi.getSelectedRows()) return;
    const selectedItem = this.gridApi.getSelectedRows()[0];
    this.stockItemSupplierInfoService.delete(this.stockItemId, selectedItem.supplierId)
    .pipe(
      tap(() => {
        this.rowData = this.rowData?.filter(i => i !== selectedItem);
      })
    ).subscribe();
  }

  getSuppliers() {
    this.inventoryService
        .getSuppliersForProductScreen()
        .pipe(
            tap((result) => {
              this.suppliers = result.filter((x) => x.active);
            }),
        )
        .subscribe();
  }

  addSupplier(): void {
    this.showSupplierSelect = true;
  }

  selectedSupplierChange(supplier: any): void {
    this.inventoryService.AddSupplierToInventoryItem(
      this.stockItemId,
      supplier.pkSupplierID
    ).pipe(
      mergeMap(() => {
        return this.stockItemSupplierInfoService.getSupplierInfosById(this.stockItemId)
      }),
      tap((result: any) => {
        this.rowData = result;
        this.showSupplierSelect = false;
      })
    ).subscribe();
  }

  makeDefault(): void {
    if (!this.gridApi.getSelectedRows()) return;
    const selectedItem = this.gridApi.getSelectedRows()[0];
    if (selectedItem.isDefault === true) return;
    this.inventoryService.setDefaultSupplierForInventoryItem(this.stockItemId, selectedItem.supplierId)
    .pipe(
      mergeMap(() => {
        return this.stockItemSupplierInfoService.getSupplierInfosById(this.stockItemId)
      }),
      tap((result: any) => {
        this.rowData = result;
      })
    )
    .subscribe();
  }

}
