import { Supplier } from "./../models/Supplier";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BedWeeklySale } from "src/app/shared/models/BedWeeklySale";
import { BedWeeklySaleCategory } from "src/app/shared/models/BedWeeklySaleCategory";
import { PurchaseOrder } from "src/app/shared/models/PurchaseOrder";
import { ReportDto } from "src/app/shared/models/ReportDto";
import { StockValuationItem } from "src/app/shared/models/StockValuationItem";
import { WeeklySale } from "src/app/shared/models/WeeklySale";

@Injectable({
  providedIn: "root",
})
export class ReportsService {
  constructor(
    @Inject("BASE_URL") private baseUrl: string,
    private http: HttpClient
  ) { }

  private getTotalBedSalesCategoryURL = "api/report/getTotalBedSalesCategory";
  getTotalBedSalesCategory(): Observable<BedWeeklySaleCategory[]> {
    return this.http.get<BedWeeklySaleCategory[]>(
      this.baseUrl + this.getTotalBedSalesCategoryURL
    );
  }

  private getTotalBedSalesCategoryExcelURL =
    "api/report/getTotalBedSalesCategoryEXCEL";
  getTotalBedSalesCategoryExcel(): Observable<Blob> {
    return this.http.get(this.baseUrl + this.getTotalBedSalesCategoryExcelURL, {
      responseType: "blob",
    });
  }

  private getSalesByChannelForSKUExcelURL =
    "api/report/getSalesByChannelForSkuEXCEL";
  getSalesByChannelForSKUExcel(id: string, dateFrom: string, dateTo: string): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(this.baseUrl + this.getSalesByChannelForSKUExcelURL + `?id=${id}&dateFrom=${dateFrom}&dateTo=${dateTo}`, {
      responseType: "blob" as "json",
      observe: "response",
    });
  }

  private getEntryDocsURL =
    "api/report/entryDocsReport";
  GetEntryDocs(dateFrom: string, dateTo: string): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(this.baseUrl + this.getEntryDocsURL + `?dateFrom=${dateFrom}&dateTo=${dateTo}`, {
      responseType: "blob" as "json",
      observe: "response",
    });
  }

  getGoodsReadyReport(): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(this.baseUrl + 'api/report/getGoodsReadyReport', {
      responseType: "blob" as "json",
      observe: "response",
    });
  }

  private getOrderItemsDataForTheLast10WeeksURL =
    "api/report/getOrderItemsDataForTheLast10Weeks";
  getBedItemsDataForTheLast10Weeks(): Observable<BedWeeklySale[]> {
    return this.http.get<BedWeeklySale[]>(
      this.baseUrl + this.getOrderItemsDataForTheLast10WeeksURL
    );
  }

  private getOrderItemsDataForTheLast10WeeksEXCEL_URL =
    "api/report/getOrderItemsDataForTheLast10WeeksEXCEL";
  getBedItemsDataForTheLast10WeeksEXCEL(): Observable<HttpResponse<Blob>> {
    return this.http.get(
      this.baseUrl + this.getOrderItemsDataForTheLast10WeeksEXCEL_URL,
      {
        responseType: "blob",
        observe: "response",
      }
    );
  }

  private getWeeklySalesNewURL = "getWeeklySalesExcelNew";
  getWeeklySalesNew(): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(this.baseUrl + this.getWeeklySalesNewURL, {
      responseType: "blob" as "json",
      observe: "response",
    });
  }

  private getNewItemsWeeklySalesURL = "api/report/new-items-sales-weekly";
  getNewItemsWeeklySales(): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(this.baseUrl + this.getNewItemsWeeklySalesURL, {
      responseType: "blob" as "json",
      observe: "response",
    });
  }

  private getContainerPalletReportByIdURL =
    "api/report/getContainerPalletReport";
  getContainerPalletReportById(
    purchaseId: string
  ): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(
      this.baseUrl + this.getContainerPalletReportByIdURL + `/${purchaseId}`,
      {
        responseType: "blob" as "json",
        observe: "response",
      }
    );
  }

  private getAmazonShippingReportURL =
    "api/report/amazonShippingReport/";
  getAmazonShippingReport(supplierId: string): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(this.baseUrl + this.getAmazonShippingReportURL + supplierId,
      {
        responseType: "blob" as "json",
        observe: "response",
      }
    );
  }

  private getLandedCostReportByStockItemURL = "api/report/landedCostByStockItemId";
  getLandedCostReportByStockItem(stockItemId: string): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(
      this.baseUrl + this.getLandedCostReportByStockItemURL + `/${stockItemId}`,
      {
        responseType: "blob" as "json",
        observe: "response",
      }
    );
  }

  private getReturnReasonForEachSKUReportURL = "api/report/returnReasonForEachSKU";
  getReturnReasonForEachSKUReport(from: string, to: string, stockItemId: string, categoryId: string): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(
      `${this.baseUrl}${this.getReturnReasonForEachSKUReportURL}/${from}/${to}/${stockItemId}/${categoryId}`,
      {
        responseType: "blob" as "json",
        observe: "response",
      }
    );
  }

  private getCashFlowReportURL = "api/report/getCashFlowReportExcel";
  getCashFlowReport(): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(this.baseUrl + this.getCashFlowReportURL, {
      responseType: "blob" as "json",
      observe: "response",
    });
  }

  getChannelLinkingReport(): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(this.baseUrl + 'api/report/channelLinking', {
      responseType: "blob" as "json",
      observe: "response",
    });
  }

  private getCashFlowWithFurstherInfoReportURL =
    "api/report/getCashFlowWithFurstherInfo";
  GetCashFlowWithFurstherInfoReport(): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(
      this.baseUrl + this.getCashFlowWithFurstherInfoReportURL,
      {
        responseType: "blob" as "json",
        observe: "response",
      }
    );
  }

  private getPOScheduleAIvanReportURL = "api/report/getPOScheduleAIvanReport";
  getPOScheduleAIvanReport(supplier: Supplier): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(
      this.baseUrl +
      this.getPOScheduleAIvanReportURL +
      `?supplierId=${supplier.pkSupplierID}&supplierName=${supplier.supplierName}`,
      {
        responseType: "blob" as "json",
        observe: "response",
      }
    );
  }

  private getPurchaseOrdersByStatusURL =
    "api/report/getPurchaseOrdersByStatus/2";
  getPurchaseOrdersByStatus(): Observable<PurchaseOrder[]> {
    return this.http.get<PurchaseOrder[]>(
      this.baseUrl + this.getPurchaseOrdersByStatusURL
    );
  }

  private getContainerReportURL = "api/report/getContainerReport";
  getContainerReport(): Observable<Blob> {
    return this.http.get(this.baseUrl + this.getContainerReportURL, {
      responseType: "blob",
    });
  }

  private getFavouriteItemsEXCEL_URL = "api/report/getFavouriteItemsEXCEL";
  getFavouriteItemsEXCEL(): Observable<Blob> {
    return this.http.get(this.baseUrl + this.getFavouriteItemsEXCEL_URL, {
      responseType: "blob",
    });
  }

  private getFBAReportEXCEL_URL = "api/report/getFBAReportEXCEL";
  getFBAReportEXCEL(): Observable<Blob> {
    return this.http.get(this.baseUrl + this.getFBAReportEXCEL_URL, {
      responseType: "blob",
    });
  }

  private getMonthlyValueOfImportsEXCEL_URL =
    "api/report/getMonthlyValueOfImportsEXCEL";
  getMonthlyValueOfImportsEXCEL(): Observable<Blob> {
    return this.http.get(
      this.baseUrl + this.getMonthlyValueOfImportsEXCEL_URL,
      {
        responseType: "blob",
      }
    );
  }

  private getPalletEXCEL_URL = "api/report/getPalletEXCEL";
  getPalletEXCEL(): Observable<Blob> {
    return this.http.get(this.baseUrl + this.getPalletEXCEL_URL, {
      responseType: "blob",
    });
  }

  private getPOListAndItemURL = "api/report/getPOListAndItem";
  getPOListAndItem(supplierID: string): Observable<Blob> {
    return this.http.get(
      this.baseUrl + this.getPOListAndItemURL + `/${supplierID}`,
      {
        responseType: "blob",
      }
    );
  }

  private getPOScheduleEXCEL_URL = "api/report/getPOScheduleEXCEL";
  getPOScheduleEXCEL(supplierID: string): Observable<Blob> {
    return this.http.get(
      this.baseUrl + this.getPOScheduleEXCEL_URL + `/${supplierID}`,
      {
        responseType: "blob",
      }
    );
  }

  private getSalesByChannelEXCEL_URL = "api/report/getSalesByChannelEXCEL";
  getSalesByChannelEXCEL(startDate: string, endDate: string): Observable<Blob> {
    return this.http.get(
      this.baseUrl +
      this.getSalesByChannelEXCEL_URL +
      `/${startDate}/${endDate}`,
      { responseType: "blob" }
    );
  }

  private getResendsReturnsRefundsEXCEL_URL = "api/report/getTotalReturnsResendsRefunds";
  getResendsReturnsRefundsEXCEL(startDate: string, endDate: string, Source: string, Subsource: string): Observable<HttpResponse<Blob>> {
    return this.http.get(this.baseUrl + this.getResendsReturnsRefundsEXCEL_URL + `/${Source}/${Subsource}/from/${startDate}/to/${endDate}`, {
      responseType: "blob",
      observe: "response",
    });
  }

  private getResendsReturnsRefundsEXCEL_URLnew = "api/report/getTotalReturnsResendsRefunds";
  getResendsReturnsRefundsEXCELnew(from: string, to: string, channelId: number): Observable<HttpResponse<Blob>> {
    return this.http.get(this.baseUrl + this.getResendsReturnsRefundsEXCEL_URLnew + `/${channelId}/${from}/${to}`, {
      responseType: "blob",
      observe: "response",
    });
  }

  getChannelSalesInSalesOrder(from: string, to: string, sourse: string, subsource: string, tag: string): Observable<HttpResponse<Blob>> {
    return this.http.get(this.baseUrl + `api/report/channelsalesinsalesorder?from=${from}&to=${to}&source=${sourse}&subsource=${subsource}` + (tag ? '&tag=${tag}' : ''), {
      responseType: "blob",
      observe: "response",
    });
  }

  private getSpatialReportExcelUrl = "api/report/getSpatialReportExcel";
  getSpatialReportExcel(
    startDate: string,
    endDate: string,
    distance: number
  ): Observable<Blob> {
    return this.http.get(
      `${this.baseUrl}${this.getSpatialReportExcelUrl}/${startDate}/${endDate}/${distance}`,
      { responseType: "blob" }
    );
  }

  private salesWithPriceChangeURL = "api/report/salesWithPriceChange";
  salesWithPriceChange(): Observable<HttpResponse<Blob>> {
    return this.http.get(this.baseUrl + this.salesWithPriceChangeURL, {
      responseType: "blob",
      observe: "response",
    });
  }

  private getSlowSellingLinesEXCEL_URL = "api/report/getSlowSellingLinesEXCEL";
  getSlowSellingLinesEXCEL(): Observable<Blob> {
    return this.http.get(this.baseUrl + this.getSlowSellingLinesEXCEL_URL, {
      responseType: "blob",
    });
  }

  private getStockValuationWithFreightCostsURL =
    "api/report/getStockValuationWithFreightCosts";
  getStockValuationWithFreightCosts(reportDate: string): Observable<Blob> {
    return this.http.get(
      this.baseUrl +
      this.getStockValuationWithFreightCostsURL +
      `/${reportDate}`,
      {
        responseType: "blob",
      }
    );
  }

  private getStockValuationURL = "api/report/getStockValuation";
  getStockValuation(reportDate: string): Observable<StockValuationItem[]> {
    return this.http.get<StockValuationItem[]>(
      this.baseUrl + this.getStockValuationURL + `/${reportDate}`
    );
  }

  private getStockValueExcelURL = "api/report/getStockValueExcel";
  getStockValueExcel(date: string): Observable<Blob> {
    return this.http.get(
      this.baseUrl + this.getStockValueExcelURL + `/${date}`,
      {
        responseType: "blob",
      }
    );
  }

  private getSupplierProductInfoEXCEL_URL =
    "api/report/getSupplierProductInfoEXCEL";
  getSupplierProductInfoEXCEL(supplierID: string): Observable<Blob> {
    return this.http.get(
      this.baseUrl + this.getSupplierProductInfoEXCEL_URL + `/${supplierID}`,
      { responseType: "blob" }
    );
  }

  private getWeeklySalesURL = "api/report/getWeeklySales";
  getWeeklySales(): Observable<WeeklySale[]> {
    return this.http.get<WeeklySale[]>(this.baseUrl + this.getWeeklySalesURL);
  }

  private getWeeklySalesExcelURL = "api/report/getWeeklySalesExcel";
  getWeeklySalesExcel(): Observable<HttpResponse<Blob>> {
    return this.http.get(this.baseUrl + this.getWeeklySalesExcelURL, {
      responseType: "blob",
      observe: "response",
    });
  }

  private getBarcodeReportURL = "api/report/getBarcodeReport";
  getBarcodeReport(pkSupplierID: string): Observable<Blob> {
    return this.http.get(
      this.baseUrl + this.getBarcodeReportURL + `/${pkSupplierID}`,
      {
        responseType: "blob",
      }
    );
  }

  private getSupplierProductInfoWithPhotosEXCEL_URL =
    "api/report/getSupplierProductInfoWithPhotosEXCEL";
  getSupplierProductInfoWithPhotosEXCEL(
    pkSupplierID: string,
    categoryId: string
  ): Observable<Blob> {
    return this.http.get(
      this.baseUrl +
      this.getSupplierProductInfoWithPhotosEXCEL_URL +
      `/${pkSupplierID}/${categoryId}`,
      { responseType: "blob" }
    );
  }

  private getWowStockReportURL = "api/report/getWowStockReport";
  GetWowStockReport(): Observable<Blob> {
    return this.http.get(this.getWowStockReportURL, { responseType: "blob" });
  }

  getWowcherAllocationReport(): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>('api/report/wowcher-allocation', {
      responseType: "blob" as "json",
      observe: "response",
    });
  }

  getSellerFlexAllocationReport(): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>('api/report/seller-flex-allocation', {
      responseType: "blob" as "json",
      observe: "response",
    });
  }

  getWowcherTransferReport(): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>('api/report/wowcher-transfer', {
      responseType: "blob" as "json",
      observe: "response",
    });
  }

  private getFBASalesReportURL = "api/report/getFBASalesReport";
  GetFBASalesReport(): Observable<Blob> {
    return this.http.get(this.getFBASalesReportURL, { responseType: "blob" });
  }

  private getMonthlySalesReportURL = "api/report/getMonthlySalesReport";
  GetMonthlySalesReport(): Observable<Blob> {
    return this.http.get(this.getMonthlySalesReportURL, {
      responseType: "blob",
    });
  }

  private getPaymentReportURL = "api/report/getPaymentReport";
  GetPaymentReport(
    pkSupplierId: string,
    startDate: string,
    endDate: string
  ): Observable<Blob> {
    return this.http.get(
      this.getPaymentReportURL +
      `/${pkSupplierId}/from_${startDate}_to_${endDate}`,
      {
        responseType: "blob",
      }
    );
  }

  private getWayfairSalesDatesSetReportURL =
    "api/report/getWayfairSalesDatesSetReport";
  GetWayfairSalesDatesSetReport(
    startDate: string,
    endDate: string
  ): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(
      this.getWayfairSalesDatesSetReportURL +
      `/from_${startDate}_to_${endDate}`,
      {
        responseType: "blob" as "json",
        observe: "response",
      }
    );
  }

  private getAmazonSalesDatesSetReportURL =
    "api/report/getAmazonSalesDatesSetReport";
  GetAmazonSalesDatesSetReport(
    startDate: string,
    endDate: string
  ): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(
      this.getAmazonSalesDatesSetReportURL +
      `/from_${startDate}_to_${endDate}`,
      {
        responseType: "blob" as "json",
        observe: "response",
      }
    );
  }

  private getAmazonSalesEuropeReportURL =
    "api/report/amazonSalesEurope";
  public GetAmazonSalesEuropeReport(
    from: string,
    to: string
  ): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(
      this.getAmazonSalesEuropeReportURL +
      `/${from}/${to}`,
      {
        responseType: "blob" as "json",
        observe: "response",
      }
    );
  }

  private getEbaySalesDatesSetReportURL =
    "api/report/getEbaySalesDatesSetReport";
  GetEbaySalesDatesSetReport(
    startDate: string,
    endDate: string
  ): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(
      this.getEbaySalesDatesSetReportURL + `/from_${startDate}_to_${endDate}`,
      {
        responseType: "blob" as "json",
        observe: "response",
      }
    );
  }

  private getWowSalesURL = "api/report/getWowSales";
  GetWowSalesReport(startDate: string, endDate: string): Observable<Blob> {
    return this.http.get(
      this.getWowSalesURL + `/from_${startDate}_to_${endDate}`,
      {
        responseType: "blob",
      }
    );
  }

  private getWayfairSalesWeeklyReportURL =
    "api/report/getWayfairSalesWeeklyReport";
  GetWayfairSalesWeeklyReport(): Observable<Blob> {
    return this.http.get(this.getWayfairSalesWeeklyReportURL, {
      responseType: "blob",
    });
  }

  private getPhotoPerSKUReportURL = "api/report/getPhotoPerSKUReport";
  GetPhotoPerSKUReport(): Observable<Blob> {
    return this.http.get(this.getPhotoPerSKUReportURL, {
      responseType: "blob",
    });
  }

  private getDeliveredPurchaseOrdersURL =
    "api/report/getDeliveredPurchaseOrders";
  GetDeliveredPurchaseOrders(
    startdate: string,
    endDate: string
  ): Observable<Blob> {
    return this.http.get(
      this.getDeliveredPurchaseOrdersURL +
      `?startdate=${startdate}&endDate=${endDate}`,
      {
        responseType: "blob",
      }
    );
  }

  private getWowCostingReportURL = "api/report/getWowCostingReport";
  GetWowCostingReport(): Observable<Blob> {
    return this.http.get(this.getWowCostingReportURL, {
      responseType: "blob",
    });
  }

  private getShippingForwarderReportURL =
    "api/report/getShippingForwarderReport";
  GetShippingForwarderReport(
    startdate: string,
    endDate: string,
    onlyWithFreightUSDUnpaid: boolean
  ): Observable<Blob> {
    return this.http.get(
      this.getShippingForwarderReportURL +
      `?startdate=${startdate}&endDate=${endDate}&onlyWithFreightUSDUnpaid=${onlyWithFreightUSDUnpaid}`,
      {
        responseType: "blob",
      }
    );
  }

  private getUkFreightcostReportURL = "api/report/getUkFreightCostReport";
  GetUkFreightCostReport(from: string, to: string): Observable<Blob> {
    return this.http.get(
      `${this.getUkFreightcostReportURL}?from=${from}&to=${to}`,
      { responseType: "blob" }
    );
  }

  private getFbaUkStockReportURL = "api/report/getFbaUkStockReport";
  GetFbaUkStockReport(): Observable<Blob> {
    return this.http.get(this.getFbaUkStockReportURL, {
      responseType: "blob",
    });
  }

  private getFbaEuropeStockReportURL = "api/report/getFbaEuropeStockReport";
  GetFbaEuropeStockReport(): Observable<Blob> {
    return this.http.get(this.getFbaEuropeStockReportURL, {
      responseType: "blob",
    });
  }

  private getFbaStockReportURL = "api/report/getSellerFlexStockReport";
  getSellerFlexStockReport(): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(this.getFbaStockReportURL, {
      responseType: "blob" as "json",
      observe: "response",
    });
  }

  private getFbaStockReportURLNew = "api/report/getSellerFlexStockReportNew";
  getSellerFlexStockReportNew(): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(this.getFbaStockReportURLNew, {
      responseType: "blob" as "json",
      observe: "response",
    });
  }

  private getSellerFlexStockLowQtyReportURL = "api/report/getSellerFlexStockLowQtyReport";
  getSellerFlexStockLowQtyReport(): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(this.getSellerFlexStockLowQtyReportURL, {
      responseType: "blob" as "json",
      observe: "response",
    });
  }

  private getCarrierReportURL = "api/report/getCarrierReportExcel";
  getCarrierReport(): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(this.getCarrierReportURL, {
      responseType: "blob" as "json",
      observe: "response",
    });
  }

  getGo2StreamReport(selected: string): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(`api/report/go2stream/${selected}`, {
      responseType: "blob" as "json",
      observe: "response",
    });
  }

  private getStockTakeSheetReportURL = "api/report/getStockTakeSheetReport";
  getStockTakeSheetReport(): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(this.getStockTakeSheetReportURL, {
      responseType: "blob" as "json",
      observe: "response",
    });
  }

  private getStockTakeRandomReportURL = "api/report/getStockTakeRandomReport";
  getStockTakeRandomReport(): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(this.getStockTakeRandomReportURL, {
      responseType: "blob" as "json",
      observe: "response",
    });
  }

  private getStockTakeLowQuantityReportURL = "api/report/getStockTakeLowQuantityReport";
  getStockTakeLowQuantityReport(): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(this.getStockTakeLowQuantityReportURL, {
      responseType: "blob" as "json",
      observe: "response",
    });
  }

  getArrowAirdrie(): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>("api/report/getArrowAirdrieReport", {
      responseType: "blob" as "json",
      observe: "response",
    });
  }

  getArrowEnfield(): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>("api/report/getArrowEnfieldReport", {
      responseType: "blob" as "json",
      observe: "response",
    });
  }

  getArrowWigan(): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>("api/report/getArrowWiganReport", {
      responseType: "blob" as "json",
      observe: "response",
    });
  }

  getArrowWorcester(): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>("api/report/getArrowWorcesterReport", {
      responseType: "blob" as "json",
      observe: "response",
    });
  }

  private getSalesByWeekReportURL = "api/report/salesByWeek";
  getSalesByWeekReport(): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(this.getSalesByWeekReportURL, {
      responseType: "blob" as "json",
      observe: "response",
    });
  }

  private getStockValueParentsOnlyUrl = "api/report/getStockValueParentsOnly/";
  getStockValueParentsOnly(reportDate: string): Observable<Blob> {
    return this.http.get(this.getStockValueParentsOnlyUrl + reportDate, {
      responseType: "blob",
    });
  }

  private getOrdersFromTuffnellsUrl = "api/report/getOrdersFromTuffnells/";
  GetOrdersFromTuffnells(startdate: string, endDate: string): Observable<Blob> {
    return this.http.get(
      this.getOrdersFromTuffnellsUrl +
      `?startdate=${startdate}&endDate=${endDate}`,
      {
        responseType: "blob",
      }
    );
  }

  private getTotalCbmAndTotalWeightByOrderURL =
    "api/report/getTotalCbmAndTotalWeightByOrder";
  GetTotalCbmAndTotalWeightByOrder(trackingNumbers: string): Observable<Blob> {
    return this.http.get(
      this.getTotalCbmAndTotalWeightByOrderURL +
      `?trackingNumbers=${trackingNumbers}`,
      {
        responseType: "blob",
      }
    );
  }

  private getStockValueWithSoldFreightURL =
    "api/report/getStockValueWithSoldFreight";
  GetStockValueWithSoldFreight(
    startdate: string,
    endDate: string
  ): Observable<Blob> {
    return this.http.get(
      this.getStockValueWithSoldFreightURL +
      `?startdate=${startdate}&endDate=${endDate}`,
      {
        responseType: "blob",
      }
    );
  }

  public sendStockValueWorking(date: string, recepients: string[]): Observable<object> {
    return this.http.post(`api/report/stockValueWorking/${date}`, recepients);
  }

  public sendStockValueSummary(date: string, recepients: string[]): Observable<object> {
    return this.http.post(`api/report/stockValueSummary/${date}`, recepients);
  }
}
