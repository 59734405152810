
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { saveAs } from "file-saver-es";
import * as moment from "moment";
import { Channel } from "../../../shared/models/channel";
import { InventoryService } from "../../../shared/services/inventory.service";
import { ReportsService } from "../../../shared/services/reports.service";
import { SnackBarService } from "../../../core/services/snack-bar.service";
 
@Component({
  selector: 'app-channel-sales-in-sales-order',
  templateUrl: './channel-sales-in-sales-order.component.html',
  styleUrls: ['./channel-sales-in-sales-order.component.css']
})
export class ChannelSalesInSalesOrderComponent implements OnInit {

  loadingReport = false;
    hideDownloadButton = true;
    currentDate = new Date();
    //
    range = new UntypedFormGroup({
        start: new UntypedFormControl(),
        end: new UntypedFormControl(),
    });

    channels: Channel[];
    selectedChannel: Channel;

  constructor(
    private reportsService: ReportsService,
            private invetoryService: InventoryService,
            private snackBar: SnackBarService,
  ) { }

  ngOnInit(): void {
    this.invetoryService.getAllChannels()
    .pipe()
    .subscribe((channels: Channel[]) => {
      this.channels = channels;
    });
  }

  downloadFile() {
          if (this.range.value["start"] == null || this.range.value["end"] == null) {
              this.snackBar.warning("Pick the date range please");
          } else if (!this.selectedChannel) {
            this.snackBar.warning("Select channel please");
          } else {
              // this.loadingReport = true;
  
              var dstart = this.range.value["start"];
              var dend = this.range.value["end"];
              return this.reportsService
                  .getChannelSalesInSalesOrder(moment(dstart).format("YYYY-MM-DD"), moment(dend).format("YYYY-MM-DD"), this.selectedChannel.source, this.selectedChannel.subsource, this.selectedChannel.tag)
                  .subscribe(
                      (result: any) => {
                          if (result.type != "text/plain") {
                              this.loadingReport = false;
                              let filename = result.headers
                                  .get("content-disposition")
                                  .split(";")[1]
                                  .split("=")[1]
                                  .replace(/\"/g, "");
                              var blob = new Blob([result.body]);
                              saveAs(blob, filename);
                          }
                      }
                  );
          }
      }

}

