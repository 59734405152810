import { Component, OnInit } from '@angular/core';
import { ReportsService } from "src/app/shared/services/reports.service";
import { HelperService } from "src/app/shared/services/helper.service";

@Component({
  selector: 'app-channel-linking',
  templateUrl: './channel-linking.component.html',
  styleUrls: ['./channel-linking.component.css']
})
export class ChannelLinkingComponent implements OnInit {

  constructor(
    private reportsService: ReportsService,
    private helper: HelperService
  ) {}

  currentDate = new Date();
  ngOnInit(): void {}

  downloadReport(): void {
    this.reportsService.getChannelLinkingReport().subscribe((response) => {
      this.helper.saveFile(response);
    });
  }
}
